import { Injectable } from '@angular/core';
import { AmountSource, FactorRecipient, RateSource, WageTypeConnecting } from '@uni-entities';
import { BizHttp, UniHttp } from '@uni-framework/core/http';
import { of } from 'rxjs';

export const rateSourceTypes: { ID: RateSource; Name: string }[] = [
    { ID: RateSource.WageType, Name: 'Følgelønnsartens innstillinger for sats' },
    { ID: RateSource.SalaryTransaction, Name: 'Hovedlønnsartens lønnspost' },
    { ID: RateSource.SalaryTransactionSum, Name: 'Sum av hovedlønnsartens lønnspost' },
];

export const amountSourceTypes: { ID: AmountSource; Name: string }[] = [
    { ID: AmountSource.SetToOne, Name: 'Skal alltid være 1' },
    { ID: AmountSource.SalaryTransaction, Name: 'Følger hovedlønnsartens lønnspost' },
];

export const factorRecipientTypes: { ID: FactorRecipient; Name: string }[] = [
    { ID: FactorRecipient.NoFactor, Name: 'Ingen faktor' },
    { ID: FactorRecipient.Rate, Name: 'Sats' },
    { ID: FactorRecipient.Amount, Name: 'Antall' },
];

@Injectable({ providedIn: 'root' })
export class WageTypeConnectingService extends BizHttp<WageTypeConnecting> {
    constructor(protected http: UniHttp) {
        super(http);
        this.relativeURL = WageTypeConnecting.RelativeUrl;
        this.entityType = WageTypeConnecting.EntityType;
    }

    public getRateSourceTypes() {
        return of(rateSourceTypes);
    }
    public getAmountSourceTypes() {
        return of(amountSourceTypes);
    }

    public getfactorRecipientTypes() {
        return of(factorRecipientTypes);
    }
}
