import { Ticker } from '@app/services/common/uniTickerService';
import { UniTableConfig } from '@uni-framework/ui/unitable';
import { rigDate } from '@app/components/common/utils/rig-date';

export const TickerTableConfigOverrides: {
    [tickerCode: string]: (tableConfig: UniTableConfig, ticker?: Ticker, isSubTicker?: boolean) => UniTableConfig;
} = {
    journalentryline_list: (config, ticker, isSubTicker) => {
        if (isSubTicker) {
            return config;
        }

        return config.setQuickFilters([
            {
                field: 'Period.AccountYear',
                operator: 'eq',
                label: 'Regnskapsår',
                ignoreColumnVisibility: true,
                width: '10rem',
            },
            { field: 'Account.AccountNumber', width: '10rem' },
            { field: 'SubAccount.AccountNumber', width: '10rem' },
            { field: 'JournalEntryLine.Amount', width: '10rem' },
            {
                field: '_showCredited',
                type: 'checkbox',
                label: 'Vis krediterte',
                filterGenerator: (isChecked) => (isChecked ? '' : 'isnull(StatusCode,0) ne 31004'),
            },
        ]);
    },
    bank_list: (config, ticker) => {
        const activeFilter = ticker?.Filters?.find((f) => f.IsActive);
        if (activeFilter?.Code === 'incomming_without_match') {
            return config.setQuickFilters([
                {
                    field: 'show_hidden_payments',
                    label: 'Vis skjulte betalinger',
                    type: 'checkbox',
                    filterGenerator: (isChecked) => (isChecked ? '' : 'isnull(StatusCode,0) ne 44020'),
                },
            ]);
        }

        return config;
    },
    employment_list: (config) => {
        return config.setQuickFilters([
            {
                field: '',
                type: 'select',
                options: [
                    { label: 'Alle', value: undefined },
                    { label: 'Aktive', value: true },
                    { label: 'Avsluttede', value: false },
                ],
                filterGenerator: (selectedOption) => {
                    const value = selectedOption?.value;
                    const today = rigDate().format('YYYY-MM-DD');
                    if (value === true) {
                        return `isnull(StartDate,'2099-01-01') le '${today}' and isnull(EndDate,'2099-01-01') gt '${today}'`;
                    } else if (value === false) {
                        return `EndDate le '${today}'`;
                    }
                },
            },
        ]);
    },
    employee_list: (config) => {
        return config.setQuickFilters([
            {
                field: '',
                type: 'select',
                optionsEndpoint: `/api/statistics?model=EmployeeCategory&select=ID as value,Name as label&filter=IsVisible eq 'true'`,
                filterGenerator: (selectedOption) => {
                    const value = selectedOption?.value;
                    if (value) {
                        return `EmployeeCategoryLink.EmployeeCategoryID eq ${value}`;
                    }
                },
            },
        ]);
    },
    new_supplierinvoice_list_checkbox: (config, ticker) => {
        const activeFilter = ticker?.Filters?.find((f) => f.IsActive);
        if (activeFilter?.Code === 'unpaid_list_supplierinvoices') {
            return config.setQuickFilters([
                {
                    field: '_onlyJournaled',
                    label: 'Se bare bokførte',
                    type: 'checkbox',
                    filterGenerator: (isChecked) => isChecked && { field: 'StatusCode', operator: 'eq', value: 30104 },
                },
            ]);
        }

        return config;
    },
    projects_list: (config, ticker) => {
        return config.setQuickFilters([
            {
                field: 'ProjectLeadName',
                operator: 'contains',
                label: 'Prosjektleder',
                ignoreColumnVisibility: true,
                width: '15rem',
            },
        ]);
    },
};
