import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Ticker, TickerColumn, ITickerColumnOverride, UniTickerService } from '@app/services/common/uniTickerService';
import { get } from 'lodash-es';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'uni-ticker-detail-view',
    templateUrl: './tickerDetailView.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTickerDetailView {
    @Input() public ticker: Ticker;
    @Input() public model: any;
    @Input() public columnOverrides: Array<ITickerColumnOverride> = [];

    public fieldGroups: TickerColumn[][];

    constructor(private tickerService: UniTickerService) {}

    public ngOnChanges() {
        if (this.ticker && this.model) {
            let fields = this.ticker.Columns.filter((c) => c.Type !== 'dontdisplay');
            fields = this.setValuesOnFields(fields);

            this.fieldGroups = this.groupFields(fields);
        }
    }

    private setValuesOnFields(fields: TickerColumn[]): TickerColumn[] {
        return fields.map((field) => {
            field['_class'] = '';

            let value: string = this.tickerService.getFieldValue(field, this.model, this.ticker, this.columnOverrides);

            if (field.Type === 'address' && value) {
                let valueParts = value.split(' - ');
                value = valueParts.shift() + '\n' + valueParts.join(' ');
            }

            if (field.Format === 'DatePassed' && value) {
                let date = get(this.model, field.Alias || field.Field);
                field['_class'] = rigDate(date).isAfter(rigDate()) ? 'date-good' : 'date-bad';
            }

            field['_value'] = value;
            return field;
        });
    }

    private groupFields(fields: TickerColumn[]) {
        return fields.reduce((groups, field) => {
            // -1 because we want the fieldsetColumns in config to start at 1,
            // but obviously arrays are zero-based
            let fieldsetCol = (+field.FieldSetColumn || 1) - 1;
            if (!groups[fieldsetCol]) {
                groups[fieldsetCol] = [];
            }

            groups[fieldsetCol].push(field);
            return groups;
        }, []);
    }

    public getVisibleColumns() {
        return this.ticker.Columns.filter((x) => x.Type !== 'dontdisplay');
    }

    public getFieldValue(column: TickerColumn, model: any) {
        return this.tickerService.getFieldValue(column, model, this.ticker, this.columnOverrides);
    }
}
