<uni-tabs
    *ngIf="filters?.length > 1"
    [tabs]="filterTabs"
    [configStoreKey]="'ticker_' + ticker.Code + '_tabs'"
    [(activeIndex)]="selectedFilterIndex"
    (activeIndexChange)="onFilterSelected($event)"
>
</uni-tabs>

<ng-container *ngIf="ticker">
    <uni-ticker
        [ticker]="ticker"
        [replaceID]="replaceID"
        [selectedFilter]="selectedFilter"
        [defaultFilter]="defaultLookupFilter"
        [expressionFilters]="expressionFilters"
        [actionOverrides]="actionOverrides"
        [columnOverrides]="columnOverrides"
        [tableConfigOverride]="tableConfigOverride"
        [tableFilters]="tableFilters"
        (rowSelected)="onRowSelected($event)"
        (editModeToggled)="editModeChanged($event)"
        (tickerDataLoaded)="dataLoaded($event)"
        (rowSelectionChange)="rowSelectionChange.emit($event)"
    >
    </uni-ticker>

    <uni-sub-ticker-container
        *ngIf="!disableSubTickers && showSubTickers && ticker?.SubTickers?.length"
        (clickOutside)="hideSubTickers()"
        (close)="hideSubTickers()"
        [parentModel]="selectedRow"
        [parentTicker]="ticker"
        [subTickers]="ticker.SubTickers"
        [actionOverrides]="actionOverrides"
        [columnOverrides]="columnOverrides"
    >
    </uni-sub-ticker-container>
</ng-container>
