import { loadExcelJS } from '@app/components/common/utils/excel/excel';
import { IBizReport, IBizReportSettings } from '@app/components/reports/bizreport/IBizReport';
import { ExcelReportRenderer } from '@app/components/reports/bizreport/excelreportrenderer';
import { CsvReportRenderer } from '@app/components/reports/bizreport/csvreportrenderer';
import { ReportData } from '@app/components/reports/bizreport/ReportData';
import { ReportRender } from '@app/components/reports/bizreport/ReportRenderer';
import { exportToFile } from '@app/components/common/utils/utils';
import { HttpClient } from '@angular/common/http';
import { UniTableColumnType } from '@uni-framework/ui/unitable/config/unitableColumn';
import { lastValueFrom } from 'rxjs';

export interface ExportColumn {
    name: string;
    label?: string;
    format?: string;
}

export interface ExportOptions {
    companyName: string;
    fileName: string;
    title: string;
    route: string;
    fields: Array<ExportColumn>;
}

export class Export {
    static mapColumnType(type?: UniTableColumnType): string | undefined {
        if (!type) return;
        if (type === UniTableColumnType.DateTime || type === UniTableColumnType.LocalDate) return 'date';
        if (type === UniTableColumnType.Money || type === UniTableColumnType.Number) return 'money';
    }

    static async ExportData(http: HttpClient, options: ExportOptions) {
        const report: IBizReport = {
            Name: options.fileName,
            Title: options.title,
            Data: { routes: [{ data: options.route }] },
            Input: [],
            Layouts: [
                {
                    Name: 'Default',
                    Label: 'Default',
                    Dataset: 'data',
                    Type: 'Table',
                    Summary: 'auto',
                    Columns: options.fields.map((field) => {
                        const size = Math.min(15, Math.max(8, (field.label.length ?? 1) + 3));
                        return {
                            Name: field.name,
                            Source: undefined,
                            Label: field.label ?? field.name,
                            Format: field.format ?? '',
                            Max: size,
                        };
                    }),
                },
            ],
        };

        const settings: IBizReportSettings = { financialYear: new Date().getFullYear(), pageSize: -1 };
        const excelJS = await loadExcelJS();
        const excel = excelJS ? new ExcelReportRenderer(excelJS, report.Layouts[0], settings) : new CsvReportRenderer();

        const subTitle = '';

        let obs = ReportData.FetchData(report, http, settings);

        var exportData = await lastValueFrom(obs);
        const renderer = new ReportRender(report, exportData, settings);
        renderer.numberFormat = <any>{
            format(value) {
                return excel.formatNumber(value);
            },
        };
        renderer.createElements(<any>excel, settings.layoutIndex);
        const fileName = options.fileName + excel.getFileExtension();
        const binData = await excel.getBuffer(options.title, subTitle, options.companyName, (wb) => {});
        const txtData = !!binData ? undefined : excel.getCsvData(options.title, subTitle, options.companyName);
        exportToFile(txtData, fileName, false, binData);
    }
}
