<section class="application">
    <uni-ticker-container
        [filtersAsNavbar]="true"
        [ticker]="selectedTicker"
        [replaceID]="replaceID"
        [defaultLookupFilter]="defaultFilter"
        [actionOverrides]="actionOverrides"
        [columnOverrides]="columnOverrides"
        [tableConfigOverride]="tableConfigOverride"
        [disableSubTickers]="disableSubTickers"
        [tableFilters]="tableFilters"
        (rowSelected)="rowSelected.emit($event)"
        (rowSelectionChange)="rowSelectionChanged($event)"
    >
    </uni-ticker-container>
</section>
